import { PositionObject as Position, Piece } from 'chessboard-element'
import { Square } from 'engine/Square'

export interface Move {
  from: string
  to: string
  captured?: Piece
}

export type Color = 'White' | 'Black'

export type MoveResult =
  | 'Illegal'
  | 'Success'
  | 'Wrong Path'
  | 'Winning'
  | 'Initial'
  | 'Take Back'
export class Board {
  squares: Square[]
  private moves: Move[]

  constructor() {
    this.squares = new Array<Square>()
    this.moves = []
    for (let f = 0; f < 8; f++) {
      for (let r = 0; r < 8; r++) {
        this.squares.push(new Square(f, r))
      }
    }
  }

  get position(): Position {
    const p: Position = {}
    this.squares.forEach((square) => {
      const piece = square.piece
      if (piece !== null) {
        const sd = square.display()
        const val = piece

        //make the square type
        p[sd] = val
      }
    })
    return p
  }

  setPosition(position: Position): void {
    for (const square in position) {
      const file = square.charCodeAt(0) - 97
      const rank = square.charCodeAt(1) - 49
      const piece: Piece = position[square] as Piece
      this.placePiece(piece, file, rank)
    }
  }

  getKnightAttackedSquares(square: Square): Square[] {
    const jumps = new Array<Square>()
    const diffs = [
      [-2, -1],
      [-2, 1],
      [-1, -2],
      [-1, 2],
      [1, -2],
      [1, 2],
      [2, -1],
      [2, 1],
    ]
    diffs.forEach((d) => {
      const destFile = square.file + d[0]
      const destRank = square.rank + d[1]
      if (Board.isValidSquare(destFile, destRank)) {
        jumps.push(this.squares[Board.getSquareIndex(destFile, destRank)])
      }
    })
    return jumps
  }

  static knightCanReachSquare(
    knightSquare: Square,
    destSquare: Square
  ): boolean {
    const srcFile = knightSquare.file
    const srcRank = knightSquare.rank
    const destFile = destSquare.file
    const destRank = destSquare.rank

    if (Math.abs(srcFile - destFile) === 1) {
      return Math.abs(srcRank - destRank) === 2
    }

    if (Math.abs(srcFile - destFile) === 2) {
      return Math.abs(srcRank - destRank) === 1
    }
    return false
  }

  getRandomSquare(): Square {
    const idx = Math.floor(Math.random() * 64)
    return this.squares[idx]
  }

  getSquare(f: number, r: number): Square {
    const idx = Board.getSquareIndex(f, r)
    return this.squares[idx]
  }

  static getSquareIndex(f: number, r: number): number {
    return f * 8 + r
  }

  placePiece(p: Piece, f: number, r: number): void {
    this.getSquare(f, r).placePiece(p)
  }

  static isValidSquare(f: number, r: number): boolean {
    return f >= 0 && f <= 7 && r >= 0 && r <= 7
  }

  get moveList(): Move[] {
    return this.moves
  }

  set moveList(ml: Move[]) {
    this.moves = [...ml]
  }

  squareFromAlgebraic(alg: string): Square {
    const file = alg.charCodeAt(0) - 97
    const rank = alg.charCodeAt(1) - 49
    return this.squares[Board.getSquareIndex(file, rank)]
  }

  recordMove(
    from: string,
    to: string,
    attackingColor: Color = 'White'
  ): MoveResult {
    const fromSquare = this.squareFromAlgebraic(from)
    const toSquare = this.squareFromAlgebraic(to)

    const attackingPiece: string = attackingColor === 'White' ? 'wN' : 'bN'
    if (
      fromSquare.piece !== attackingPiece ||
      !toSquare.piece ||
      !Board.knightCanReachSquare(fromSquare, toSquare)
    ) {
      return 'Illegal'
    }

    fromSquare.piece = null
    const captured: Piece = toSquare.piece
    toSquare.piece = attackingPiece
    this.moves.push({ from, to, captured })
    return this.solved() ? 'Winning' : 'Success'
  }

  solved(): boolean {
    const filtered = this.squares.filter((square) => square.piece === null)
    return filtered.length === 63
  }

  switchColor(): void {
    this.squares.forEach((square) => {
      if (square.piece) {
        const oldColor = square.piece.charAt(0)
        const newColor = oldColor === 'b' ? 'w' : 'b'
        square.piece = square.piece.replace(oldColor, newColor)
      }
    })
    this.moves.forEach((move) => {
      if (move.captured) {
        const oldColor = move.captured.charAt(0)
        const newColor = oldColor === 'b' ? 'w' : 'b'
        move.captured = move.captured.replace(oldColor, newColor)
      }
    })
  }

  takeBack(attackingColor: Color = 'White'): void {
    if (this.moves.length === 0) {
      return
    }
    const takenBack: Move = this.moves.pop() as Move
    const restorePieceSquare = this.squareFromAlgebraic(takenBack.to)
    restorePieceSquare.piece = takenBack.captured || null
    const knightGoBackSquare = this.squareFromAlgebraic(takenBack.from)
    knightGoBackSquare.piece = attackingColor === 'White' ? 'wN' : 'bN'
  }
}
