/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Footer } from 'components/Footer'
import { MonsterKnightPuzzle } from 'components/MonsterKnightPuzzle'
import 'styles/App.css'

import GA4React from 'ga-4-react'

let ga: any

const ga4react = new GA4React('G-S9SM51TRQC')

ga4react.initialize().then(
  (ga4: any) => {
    ga = ga4
    ga4.pageview('root')
  },
  (err: any) => {
    console.error(err)
  }
)

function onGAEvent(event: string, data: any) {
  ga.gtag('event', event, data)
}

function App(): JSX.Element {
  return (
    <div className="App">
      <MonsterKnightPuzzle onGAEvent={onGAEvent} />
      <Footer />
    </div>
  )
}

export default App
