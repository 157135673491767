import * as React from 'react'
import { MonsterKnight } from 'engine/MonsterKnight'
import { Color, Move, MoveResult } from 'engine/Board'
import { objToFen, PositionObject as Position } from 'chessboard-element'
import { ChessBoardElement } from 'chessboard-element'
import 'chessboard-element'
import { BoardControls } from 'components/BoardControls'
import { Feedback } from 'components/Feeback'
import { Instructions } from 'components/Instructions'
import { MoveList } from 'components/MoveList'
import { Solution } from 'components/Solution'
import useLocalStorageState from 'use-local-storage-state'

type LevelDisplay = 'Easy' | 'Medium' | 'Hard'

const DEFAULT_LEVEL = 1
const MAX_LEVEL = 20
const PIECES_OVER_LEVEL = 3

function initPuzzle(
  num: number,
  attackedColor: Color,
  cb: ChessBoardElement | null = null
): MonsterKnight {
  if (cb) {
    cb.clear()
  }

  const puzzle = new MonsterKnight({
    numAttacked: num,
    attackedColor,
  })
  return puzzle
}

interface MonsterKnightPuzzleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGAEvent: (event: string, data: any) => void
}

export function MonsterKnightPuzzle(
  props: MonsterKnightPuzzleProps
): JSX.Element {
  const { onGAEvent } = props
  const [level, setLevel] = useLocalStorageState<number>('level', DEFAULT_LEVEL)
  const [advance, setAdvance] = useLocalStorageState<boolean>('advance', true)
  const [advanced, setAdvanced] = React.useState(false)
  const [wrongSquare, setWrongSquare] = React.useState('')
  const numPieces = level + PIECES_OVER_LEVEL
  const [attackingColor, setAttackingColor] = useLocalStorageState<Color>(
    'color',
    'White'
  )
  const attackedColor = attackingColor === 'White' ? 'Black' : 'White'
  const [puzzle, setPuzzle] = React.useState<MonsterKnight>(() =>
    initPuzzle(numPieces, attackedColor)
  )

  const chessboardRef = React.useRef<ChessBoardElement>(null)
  const [position, setPosition] = React.useState<Position>(puzzle.position)
  const [moveResult, setMoveResult] = React.useState<MoveResult>('Initial')
  const [solutions, setSolutions] = React.useState<Move[][]>([])
  const [showSolutions, setShowSolutions] = React.useState<boolean>(false)

  const wrongPath = moveResult === 'Wrong Path'

  React.useEffect(() => {
    setPosition(puzzle.position)
    setSolutions(puzzle.solve())
  }, [puzzle])

  const levelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setLevel(parseInt(event.target.value))
  }

  const levelDisplay: LevelDisplay =
    level < 7 ? 'Easy' : level > 17 ? 'Hard' : 'Medium'

  const dropHandler = (event: CustomEvent) => {
    const { source, target, setAction } = event.detail
    if (target === 'offboard') {
      return
    }

    if (wrongPath) {
      setAction('snapback')
      return
    }

    const newMoveResult = puzzle.recordMove(source, target)
    if (newMoveResult !== 'Illegal') {
      setPosition(puzzle.position)
    } else {
      setAction('snapback')
    }
    setMoveResult(newMoveResult)
    if (newMoveResult === 'Winning') {
      if (advance && !advanced && level < MAX_LEVEL) {
        setAdvanced(true)
        setLevel(level + 1)
      }
      onGAEvent('solved_puzzle', { level })
    } else if (newMoveResult === 'Wrong Path') {
      const squareStr = `square-${target}`
      const square = chessboardRef.current?.shadowRoot?.getElementById(
        squareStr
      )
      if (square) {
        setWrongSquare(squareStr)
        square.style.backgroundColor = '#ff3333'
      }
    }
  }

  const switchColor = (newColor: Color): void => {
    if (newColor !== attackingColor) {
      puzzle.switchColor()
      setAttackingColor(newColor)
      setPosition(puzzle.position)
    }
  }

  const newPuzzle = () => {
    setAdvanced(false)
    setShowSolutions(false)
    setPuzzle(initPuzzle(numPieces, attackedColor, chessboardRef.current))
    setMoveResult('Initial')
    onGAEvent('new_puzzle', { level })
  }

  const takeBack = () => {
    if (wrongSquare) {
      const square = chessboardRef.current?.shadowRoot?.getElementById(
        wrongSquare
      )
      setWrongSquare('')
      if (square) {
        square.style.backgroundColor = ''
      }
    }
    puzzle.takeBack()
    setPosition(puzzle.position)
    setMoveResult('Take Back')
  }

  const solutionsLink = showSolutions ? 'Hide Solutions' : 'Show Solutions'
  return (
    <div className="puzzle-container">
      <h3>Monster Knight (instructions below)</h3>
      <div className="board">
        <chess-board
          ref={chessboardRef}
          onDrop={dropHandler}
          position={objToFen(position)}
          draggable-pieces
          dropOffBoard="snapback"
        ></chess-board>
      </div>
      <div className="under-board">
        <MoveList
          winner={moveResult === 'Winning'}
          wrongPath={wrongPath}
          takeBack={takeBack}
          list={puzzle.board.moveList}
        />
        <Feedback moveResult={moveResult} />
        <BoardControls
          advance={advance}
          attackingColor={attackingColor}
          level={level}
          levelChange={levelChange}
          levelDisplay={levelDisplay}
          maxLevel={MAX_LEVEL}
          newPuzzle={newPuzzle}
          setAdvance={setAdvance}
          switchColor={switchColor}
        />
        <div className="solution">
          <figure>
            <button
              onClick={() => {
                setShowSolutions(!showSolutions)
              }}
            >
              {solutionsLink}
            </button>
            {showSolutions && solutions.length && (
              <Solution
                solutions={solutions}
                moveList={puzzle.board.moveList}
              />
            )}
          </figure>
        </div>
        <Instructions />
      </div>
    </div>
  )
}
