function shuffle<T>(array: Array<T>): void {
  const len = array.length
  for (let i = len - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

export { shuffle }
