import React from 'react'
import { Move } from 'engine/Board'

interface SolutionProps {
  solutions: Move[][]
  moveList: Move[]
}

export function Solution(props: SolutionProps): JSX.Element | null {
  const { moveList, solutions } = props
  const listItems = solutions.map((moves: Move[]) => {
    const key: string = moves.map((move: Move) => move.to).join(' ')
    let matching = true
    const moveListLen = moveList.length
    return (
      <tr key={key} aria-label={key}>
        {moves.map((move: Move, i: number) => {
          if (matching) {
            if (moveListLen <= i || move.to !== moveList[i].to) {
              matching = false
            }
          }
          return (
            <td
              className={`${matching ? 'matching-move ' : ''}solution-move`}
              key={move.to}
            >
              {move.to}
            </td>
          )
        })}
      </tr>
    )
  })
  return (
    <div>
      <h4>Total solutions: {listItems.length}</h4>
      <table>
        <tbody>{listItems}</tbody>
      </table>
    </div>
  )
}
