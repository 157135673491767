import React from 'react'

export function Instructions(): JSX.Element {
  return (
    <div className="instructions">
      <h4>Instructions and Information</h4>
      <ul>
        <li>
          This is a chess puzzle and a vehicle to work on chess visualization
          skills.
        </li>
        <li>
          Puzzles are random, so there is a virtually unlimited number of them.
        </li>
        <li>
          The object is to move the Knight around the board until it reaches the
          opposing king.
        </li>
        <li>You must capture a piece on each move.</li>
        <li>
          You must capture the king on the <strong>last</strong> move.
        </li>
        <li>
          To get the most benefit of the exercise, picture, in your mind, the{' '}
          <strong>entire</strong> path of the knight before making the first
          move.
        </li>
        <li>
          If you make a move that is valid but does not lead to a solution, you
          will be required to take it back.
        </li>
        <li>Check the solution(s) only if you must.</li>
        <li>
          Questions and constructive feedback are welcome. Please email
          monsterknight@pokervane.com (link below).
        </li>
      </ul>
    </div>
  )
}
