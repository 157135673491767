import React from 'react'
import { Move } from 'engine/Board'

interface MoveListProps {
  list: Move[]
  wrongPath: boolean
  winner: boolean
  takeBack: () => void
}

export function MoveList(props: MoveListProps): JSX.Element {
  const { list, takeBack, winner, wrongPath } = props
  const listLen = list.length
  const listItems = list.map((move: Move, i: number) => {
    const wrongPathClass = wrongPath && i === listLen - 1 ? ' wrong-path' : ''
    const moveClass = `move-list-move${wrongPathClass}`
    return (
      <span key={`${move.from}-${move.to}`} className={moveClass}>
        <span>{move.to}</span>
        {winner && i === listLen - 1 && (
          <span style={{ color: 'green' }}>&#10004;</span>
        )}
      </span>
    )
  })
  return (
    <>
      <div className="move-list">
        <div className="col1">
          <label>Moves:</label>
        </div>
        <div className="moves">{listItems}</div>
        <div className="col3">
          <button onClick={takeBack} disabled={listLen === 0}>
            Take Back
          </button>
        </div>
      </div>
    </>
  )
}
