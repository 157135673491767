import React from 'react'

export function Footer(): JSX.Element {
  return (
    <div className="footer">
      <div>
        Copyright &copy; 2021-{new Date().getFullYear()}, Mark E. Fraser
      </div>
      <div>
        <a href="mailto: monsterknight@pokervane.com">Questions, Comments?</a>
      </div>
    </div>
  )
}
