import { Board, Color, Move, MoveResult } from 'engine/Board'
import { Square } from 'engine/Square'
import { shuffle } from 'utils/shuffle'
import { Piece, PositionObject as Position } from 'chessboard-element'

interface Params {
  numAttacked: number
  attackedColor?: Color
  position?: Position
}

export class MonsterKnight {
  attackedColor: Color
  attackingColor: Color
  numAttacked: number
  board: Board
  knightPath: Square[]
  startingSquare?: Square
  solutions: Array<Move[]> = new Array<Move[]>()

  constructor(params: Params) {
    this.numAttacked = params.numAttacked || 3
    this.attackedColor = params.attackedColor || 'Black'
    this.attackingColor = this.attackedColor === 'Black' ? 'White' : 'Black'
    this.board = new Board()
    this.knightPath = new Array<Square>()
    if (params.position) {
      this.checkPath(params.position)
      this.setBoard(params.position)
    } else {
      this.makePath()
      this.startingSquare = this.knightPath[0]
    }
  }

  checkPath(position: Position): void {
    let prevSquare: Square | null = null
    let lastPiece
    for (const rawSquare in position) {
      if (!prevSquare) {
        const startSquare = rawSquare
        const startPiece = position[startSquare]
        const c = this.attackedColor === 'Black' ? 'w' : 'b'
        if (startPiece?.charAt(0) !== c || startPiece.charAt(1) !== 'N') {
          throw new Error('Starting piece is wrong')
        }
        prevSquare = Square.fromAlgebraicRep(rawSquare)
        this.startingSquare = prevSquare
      } else {
        const currentSquare = Square.fromAlgebraicRep(rawSquare)
        if (!Board.knightCanReachSquare(prevSquare, currentSquare)) {
          throw new Error(
            `Knight cannot reach ${rawSquare} from ${prevSquare.display()}`
          )
        }
        prevSquare = currentSquare
      }
      lastPiece = position[rawSquare]
    }
    if (prevSquare) {
      const expectedPiece = `${this.attackedColor.charAt(0).toLowerCase()}K`
      if (lastPiece !== expectedPiece) {
        throw new Error(`Path should end with ${this.attackedColor} king`)
      }
    }
  }

  checkSolution(moves: Move[]): boolean {
    const board: Board = new Board()
    board.setPosition(this.position)
    moves.forEach((move) => {
      board.recordMove(move.from, move.to, this.attackingColor)
    })
    return board.solved()
  }

  getBoard(): Board {
    return this.board
  }

  setBoard(position: Position): void {
    const startingKnight = this.attackedColor === 'Black' ? 'wN' : 'bN'
    for (const square in position) {
      const file = square.charCodeAt(0) - 97
      const rank = square.charCodeAt(1) - 49
      const piece: Piece = position[square] as Piece
      this.board.placePiece(piece, file, rank)
      if (piece === startingKnight) {
        this.startingSquare = new Square(file, rank)
        this.startingSquare.placePiece(startingKnight)
      }
    }
  }

  get position(): Position {
    return this.board.position
  }

  getKnightPath(): Square[] {
    return this.knightPath
  }

  makePath(): void {
    const startingSquare: Square = this.board.getRandomSquare()
    const startingPiece: Piece = this.attackedColor === 'Black' ? 'wN' : 'bN'
    startingSquare.placePiece(startingPiece)
    let currentSquare: Square = startingSquare
    let queenPlaced = false
    this.knightPath.push(startingSquare)
    while (this.knightPath.length < this.numAttacked + 1) {
      //    for (let i = 0; i < this.numAttacked; i++) {
      const attackedSquares = this.board.getKnightAttackedSquares(currentSquare)
      shuffle(attackedSquares)
      const newSquare = attackedSquares.find((square) => square.isEmpty())
      if (!newSquare) {
        //backtrack
        currentSquare.piece = null
        this.knightPath.pop()
        currentSquare = this.knightPath[this.knightPath.length - 1]
        continue
      }

      const pieceToPlace = this.getPieceToPlace(
        queenPlaced,
        newSquare?.rank === 0 || newSquare?.rank === 7
      )
      newSquare.placePiece(pieceToPlace)
      if (!queenPlaced && pieceToPlace.charAt(1) === 'Q') {
        queenPlaced = true
      }
      this.knightPath.push(newSquare)
      currentSquare = newSquare
    }
  }

  private getPieceToPlace(
    queenPlaced: boolean,
    noPawnsAllowed: boolean
  ): Piece {
    let pChar: string
    if (this.knightPath.length === this.numAttacked) {
      pChar = 'K'
    } else {
      let choices = ['P', 'P', 'P', 'P', 'R', 'N', 'B']
      if (!queenPlaced) {
        choices.push('Q')
      }
      if (noPawnsAllowed) {
        choices = choices.filter((c) => c !== 'P')
      }
      pChar = choices[Math.floor(Math.random() * choices.length)]
    }
    const cChar = this.attackedColor === 'Black' ? 'b' : 'w'
    return `${cChar}${pChar}` as Piece
  }

  private prematureKingCapture(toSquare: string): boolean {
    const square: Square = this.board.squareFromAlgebraic(toSquare)
    if (square.piece === this.attackedKing) {
      return this.board.squares.filter((square) => square.piece).length !== 2
    }
    return false
  }

  recordMove(from: string, to: string): MoveResult {
    if (this.prematureKingCapture(to)) {
      return 'Illegal'
    }
    const mr: MoveResult = this.board.recordMove(
      from,
      to,
      this.attackedColor === 'Black' ? 'White' : 'Black'
    )

    if (mr !== 'Winning') {
      if (!this.checkPartialSolution()) {
        return 'Wrong Path'
      }
    }
    return mr
  }

  private checkPartialSolution(): boolean {
    const ss = this.solutions.some((solution) => {
      return this.checkPartialList(solution)
    })
    return ss
  }

  private checkPartialList(moves: Move[]): boolean {
    const boardList = this.board.moveList
    const partialList = moves.slice(0, boardList.length)

    for (let i = 0; i < partialList.length; i++) {
      const boardMove = boardList[i]
      const solutionMove = partialList[i]
      if (
        boardMove.from !== solutionMove.from ||
        boardMove.to !== solutionMove.to
      ) {
        return false
      }
    }
    return true
  }

  solve(): Array<Move[]> {
    this.solutions = new Array<Move[]>()

    if (this.startingSquare) {
      const boardCopy: Board = new Board()
      boardCopy.setPosition(this.board.position)
      this.solveFromSquare(
        this.startingSquare.file,
        this.startingSquare.rank,
        boardCopy
      )
    } else {
      throw new Error('No starting square for solution.')
    }
    return this.solutions
  }

  private solveFromSquare(file: number, rank: number, board: Board) {
    const square: Square = board.getSquare(file, rank)

    if (!square.piece) {
      throw new Error('Trying to solve from empty square in solveFromSquare')
    }

    const attackedSquares = board.getKnightAttackedSquares(square)

    attackedSquares.forEach((attackedSquare) => {
      const piece = attackedSquare.piece
      if (piece) {
        if (piece === this.attackedKing) {
          const loneKing =
            board.squares.filter((square) => square.piece).length === 2
          if (loneKing) {
            board.recordMove(
              square.display(),
              attackedSquare.display(),
              this.attackingColor
            )
            this.solutions.push(board.moveList)
          }
        } else {
          const boardCopy = new Board()
          boardCopy.setPosition(board.position)
          boardCopy.moveList = board.moveList
          const result: MoveResult = boardCopy.recordMove(
            square.display(),
            attackedSquare.display(),
            this.attackingColor
          )
          if (result === 'Illegal') {
            console.log('file', file)
            console.log('rank', rank)
            console.log('boardCopy', boardCopy)
            console.log('fromSquare', square)
            console.log('toSquare', attackedSquare)
            throw new Error('Illegal Move in solveFromSquare')
          }
          this.solveFromSquare(
            attackedSquare.file,
            attackedSquare.rank,
            boardCopy
          )
        }
      }
    })
  }

  switchColor(): void {
    this.attackedColor = this.attackedColor === 'Black' ? 'White' : 'Black'
    this.attackingColor = this.attackingColor === 'Black' ? 'White' : 'Black'
    this.board.switchColor()
  }

  takeBack(): void {
    this.board.takeBack(this.attackingColor)
  }

  private get attackedKing(): Piece {
    return `${this.attackedColor === 'Black' ? 'b' : 'w'}K` as Piece
  }
}
