import React from 'react'
import { Color } from 'engine/Board'

interface BoardControlsProps {
  advance: boolean
  attackingColor: Color
  level: number
  levelChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  levelDisplay: string
  maxLevel: number
  newPuzzle: () => void
  setAdvance: (advance: boolean) => void
  switchColor: (newColor: Color) => void
}

export function BoardControls(props: BoardControlsProps): JSX.Element {
  const {
    advance,
    attackingColor,
    level,
    levelChange,
    levelDisplay,
    maxLevel,
    newPuzzle,
    setAdvance,
    switchColor,
  } = props
  return (
    <div className="board-controls">
      <div className="board-controls-row">
        <div className="level">
          <label>
            Level:
            <select disabled={false} value={level} onChange={levelChange}>
              {[...Array(maxLevel).keys()].map((n) => (
                <option key={n} value={n + 1}>
                  {n + 1}
                </option>
              ))}
            </select>
          </label>
          {levelDisplay}
        </div>
        <div>
          Attacking Color:
          <label>
            <input
              type="radio"
              defaultChecked={attackingColor === 'White'}
              onClick={() => switchColor('White')}
              value="white"
              name="attacking_color"
            />{' '}
            White
          </label>
          <label>
            <input
              type="radio"
              defaultChecked={attackingColor === 'Black'}
              onClick={() => switchColor('Black')}
              value="black"
              name="attacking_color"
            />{' '}
            Black
          </label>
        </div>
      </div>
      <div className="board-controls-row">
        <div>
          <label title="Automatically increase level after solving puzzle">
            <input
              type="checkbox"
              defaultChecked={advance}
              onChange={() => {
                setAdvance(!advance)
              }}
            />
            Auto-advance
          </label>
        </div>
      </div>
      <div className="board-controls-row">
        <button onClick={newPuzzle}>New Puzzle</button>
      </div>
    </div>
  )
}
