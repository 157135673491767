import { Piece, PositionObject as Position } from 'chessboard-element'

export class Square {
  file: number
  rank: number
  piece: Piece | null

  constructor(f: number, r: number) {
    this.file = f
    this.rank = r
    this.piece = null
  }

  isEmpty(): boolean {
    return this.piece === null
  }

  getPiece(): Piece | null {
    return this.piece
  }

  placePiece(p: Piece): void {
    this.piece = p
  }

  display(): string {
    return `${String.fromCharCode(97 + this.file)}${this.rank + 1}`
  }

  static fromAlgebraicRep(rep: string): Square {
    return new Square(rep.charCodeAt(0) - 97, rep.charCodeAt(1) - 49)
  }

  static fromPosition(p: Position): Square[] {
    const squares: Square[] = new Array<Square>()
    for (const square in p) {
      const s: Square = Square.fromAlgebraicRep(square)
      s.placePiece(p[square] as Piece)
      squares.push(s)
    }
    return squares
  }
}
