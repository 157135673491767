import React from 'react'
import { MoveResult } from 'engine/Board'

interface FeedbackProps {
  moveResult: MoveResult
}

export function Feedback({ moveResult }: FeedbackProps): JSX.Element {
  let feedback: string
  switch (moveResult) {
    case 'Winning':
      feedback = 'Congratulations, you have solved the puzzle!'
      break
    case 'Success':
      feedback = 'Good move!'
      break
    case 'Illegal':
      feedback = 'Sorry that move is not allowed here.'
      break
    case 'Wrong Path':
      feedback =
        'Sorry, that is not the right path. Please take back your move to try again.'
      break
    default:
      feedback = ''
  }
  return (
    <div className="feedback">
      Feedback: <span className="feedback-text">{feedback}</span>
    </div>
  )
}
